import {
  SrfLetterboxPlayer,
  GENERATED_PLAYER_CONTAINER,
} from './SrfLetterboxPlayer';
import {
  MEDIA_CLICKED,
  MEDIA_PAUSED,
  MEDIA_PLAYING,
  MEDIA_TIME_UPDATE,
  onEvent,
  POLL_SUBMITTED,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';
import DebounceHelper from '@fec/frontend/foundation/client/debounce-helper';
import {
  getMediumProgress,
  setMediumProgress,
} from '@fec/assets/js/utils/media-progress';

const HOOK_SELECTOR = 'js-media';
// how often should the progress list in the localstorage be updated?
// higher = less often, lower = more often (but more demanding on I/O)
const MEDIA_PROGRESS_UPDATE_TIME = 1000;

export class SrfLetterboxMedia {
  constructor(element) {
    this.$element = $(element);
    this.letterboxPlayer = null;
    this.isActive = false;
    this.elementId = this.$element.attr('id');
    this.assetId = this.$element.attr('data-assetid');
    this.hasDisabledTp = false;
    this.mediaType = this.$element.attr('data-mediatype');

    this.$medium = $('.medium', element);
    this.$mediaCaption = $('.media-caption', element);
    this.$playIcon = $('.js-play-icon', element);
    this.$spinner = $('.js-spinner', element);
    this.$tpWrapper = $('.js-player-wrapper', element);

    this.registerListener();
    this.triggerClick();
  }

  registerListener() {
    // handle clicks on this instance of the media element
    this.$element.on('click', (e) => {
      if (
        $(e.target).parents(`.${GENERATED_PLAYER_CONTAINER}`).length === 1 ||
        $(e.target).closest(`.${HOOK_SELECTOR}`).length === 0
      ) {
        // we have to ignore clicks within the letterbox-web player. relevant
        // click events are triggered and caught via MEDIA_CLICKED event (see
        // below)
        // note: the second condition is needed due to the player's menus,
        // which are documentFragments. their events bubble up but the target
        // is not within js-media
        e.stopPropagation();
      } else {
        // clicks elsewhere in the media element
        e.preventDefault();
        this.triggerClick();
      }
    });

    this.$element.on('keypress', (event) => {
      // only keypresses with the Enter key on the element itself are relevant,
      // ignore other keys on children of the medium (otherwise interacting
      // with the media controls might trigger a pause/play).
      if (
        parseInt(event.which) === KEYCODES.enter &&
        event.target === event.currentTarget
      ) {
        this.triggerClick();
      }
    });

    // handle clicks on any player on the same page
    onEvent({
      eventName: MEDIA_CLICKED,
      eventHandler: ({ detail }) => this.handleClick(detail),
    });

    // needed by the "play now" button (srf-play-now-button.js)
    onEvent({
      eventName: MEDIA_PAUSED,
      eventHandler: ({ detail }) => this.handleMediaPaused(detail),
    });

    // handle any started player on the same page
    onEvent({
      eventName: MEDIA_PLAYING,
      eventHandler: ({ detail }) => this.handleMediaPlaying(detail),
    });

    // updates the current play position of the medium in the local storage
    onEvent({
      eventName: MEDIA_TIME_UPDATE,
      eventHandler: DebounceHelper.throttle(
        ({ detail }) => this.updatePlayPositionStore(detail),
        MEDIA_PROGRESS_UPDATE_TIME,
      ),
    });

    // when any poll is taken - all players need to be stopped (and everything set to default)
    onEvent({
      eventName: POLL_SUBMITTED,
      eventHandler: () => this.deactivate(),
    });
  }

  /* notify all media elements on the same page (including this instance) that a click has happened */
  triggerClick() {
    triggerEvent(MEDIA_CLICKED, {
      id: this.elementId,
      assetId: this.assetId,
    });
  }

  /* general click handler */
  handleClick(data) {
    const isClickedItem = this.isClickedItem(data);

    // handle media with disabled letterbox player (media window!)
    this.updateHasDisabledTp(); // the disabled TP flag can change depending on the breakpoint
    if (isClickedItem && this.hasDisabledTp) {
      this.setVisualStatePlaying();
      return;
    }

    // handle letterbox player media - default case
    if (isClickedItem) {
      if (this.isActive) {
        this.pause();
      } else {
        this.setVisualStateLoading();
        this.play();
      }
    } else {
      this.deactivate();
    }
  }

  handleMediaPaused(data) {
    if (data.id === this.$tpWrapper.attr('id')) {
      this.isActive = false;
    }
  }

  handleMediaPlaying(data) {
    if (data.id === this.$tpWrapper.attr('id')) {
      this.isActive = true;
      this.setVisualStatePlaying();
    } else if (!this.hasDisabledTp) {
      this.deactivate();
    }
  }

  deactivate() {
    this.isActive = false;

    if (this.letterboxPlayer !== null) {
      this.pause();
    }
    this.setVisualStateDefault();
  }

  pause() {
    this.isActive = false;
    this.letterboxPlayer.pause();
  }

  play() {
    this.isActive = true;

    if (this.letterboxPlayer === null) {
      // check if there's a saved progress in the localstorage for this medium
      const mediumProgress = SrfLetterboxMedia.getMediumProgress(this.assetId);

      this.letterboxPlayer = new SrfLetterboxPlayer({
        containerWrapperId: this.$tpWrapper.attr('id'),
        assetId: this.assetId,
        shouldSubscribeToTimeline: this.mediaType === 'audio',
        initialSeek: mediumProgress?.seconds,
      });

      // while playing, hide all elements in the player wrapper that is NOT the generated player itself
      this.$tpWrapper.children().not(`.${GENERATED_PLAYER_CONTAINER}`).hide();
    } else {
      this.letterboxPlayer.play();
    }
  }

  setVisualStateDefault() {
    // audios which were previously "open" and not a TME, should be closed and hidden from the screenreaders
    const shouldHideFromScreenReaders =
      this.$element.data('mediatype') === 'audio' &&
      this.$tpWrapper.hasClass('player-wrapper--open') &&
      !this.$tpWrapper.hasClass('player-wrapper--tme-audio');

    this.$tpWrapper.removeClass('player-wrapper--open');

    if (shouldHideFromScreenReaders) {
      // hide the whole player wrapper, so that screenreaders and tab users can't reach it anymore
      this.$tpWrapper.one('transitionend', () =>
        this.$tpWrapper.addClass('h-element--hide'),
      );
    }

    this.$playIcon.show();
    this.$spinner.hide();
    this.$medium.removeClass('medium--active js-medium-is-active');
    this.$mediaCaption.removeClass('media-caption--active');
  }

  setVisualStateLoading() {
    this.$playIcon.hide();
    this.$spinner.show();
    this.$medium.addClass('medium--active js-medium-is-active');
    this.$mediaCaption.addClass('media-caption--active');
  }

  setVisualStatePlaying() {
    this.$tpWrapper
      .addClass('player-wrapper--open')
      // make the player wrapper visible again, especially for screenreaders and tab users
      .removeClass('h-element--hide');
    this.$spinner.hide();
    this.$medium.addClass('medium--active js-medium-is-active');
    this.$mediaCaption.addClass('media-caption--active');
  }

  isClickedItem(data) {
    return data.id === this.elementId;
  }

  /* update the hasDisabledTp flag according to the current DOM state */
  updateHasDisabledTp() {
    this.hasDisabledTp =
      parseInt(this.$element.attr('data-disable-internal-player')) === 1;
  }

  updatePlayPositionStore(data) {
    // only do something if this is "our" media that's playing
    if (this.assetId !== data.assetId) {
      return;
    }

    setMediumProgress(data.assetId, data.seconds, data.duration);
  }

  static getMediumProgress(urn) {
    const $element = $(`[data-assetid="${urn}"]`);
    if ($element.data('starttime')) {
      return { seconds: $element.data('starttime') };
    }

    return getMediumProgress(urn);
  }

  getId() {
    return this.elementId;
  }
}
