/**
 * Assets for Articles (Web)
 */
// styles
import '../../webview/scss/main.scss';
import '../css/survey-gizmo.scss';

// common js
import { commonWeb } from '../../common/js/main';

// custom article modules
import { init as srfMediaInit } from '../../webview/js/srf-media';
import { init as srfDateline } from '@fec/frontend/elements/text/article-author/srf-dateline.js';
import { init as sharingBarInit } from '@fec/frontend/modules/content/sharing-bar/sharing-bar';
import { init as srfQualityAssuranceInit } from '../../webview/js/srf-quality-assurance';
import { init as mediaWindowInit } from '@fec/frontend/elements/media/media-window/media-window';
import { init as mediumInit } from '@fec/frontend/modules/content/medium/init';
import { init as srfPlayNowButton } from '../../webview/js/srf-play-now-button';
import { init as srfIframeInit } from '../../webview/js/srf-iframe-adjust';
import { init as srfIframeModalInit } from '../../webview/js/srf-iframe-modal';
import { init as srfIframeEventTrack } from '../../webview/js/srf-iframe-event-track';
import { init as srfAutoboxInit } from '../../webview/js/srf-autobox';
import { init as expandableBoxInit } from '@fec/frontend/modules/content/expandable-box/expandable-box';
import { init as srfPseudoLinks } from '../../webview/js/srf-pseudo-links';
import { init as srfReachBottom } from '../../webview/js/srf-reach-bottom';
import { init as textareaInit } from '@fec/frontend/elements/form/textarea/textarea';
import { init as srfTeaserMetaInit } from '@fec/frontend/elements/text/teaser-meta/teaser-meta.controller';
import { init as srfCommentsInit } from '../../webview/js/srf-comments-api';

import '@fec/frontend/foundation/utils/easings';
import '@fec/frontend/modules/containers/modal/modal';

$(document).ready(function () {
  commonWeb();

  srfDateline();
  sharingBarInit();
  srfQualityAssuranceInit();
  srfMediaInit();
  mediaWindowInit();
  mediumInit();
  srfPlayNowButton();
  srfIframeInit();
  srfIframeModalInit();
  srfIframeEventTrack();
  srfAutoboxInit();
  expandableBoxInit();
  srfPseudoLinks();
  srfReachBottom();
  textareaInit();
  srfTeaserMetaInit();
  srfCommentsInit();
});
