import { SrfPseudoLinksHandler } from './classes/SrfPseudoLinksHandler';

const HOOK_SELECTOR = '.js-pseudo-links';

export function init() {
  $(HOOK_SELECTOR)
    .filter((_, element) => !$(element).data('pseudo-link-initialized'))
    .each((_, element) => {
      new SrfPseudoLinksHandler(element);

      // mark element, so that it won't be initialized again by this module
      $(element).data('pseudo-link-initialized', true);
    });
}
