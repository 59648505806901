import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';

export class SrfPseudoLinksHandler {
  constructor(element) {
    this.element = element;
    this.registerListener();

    // mark link visually
    this.element.classList.add('pseudo-link');
  }

  registerListener() {
    this.element.addEventListener(
      'click',
      (e) => this.linkActionHandler(e),
      true,
    );

    this.element.addEventListener(
      'keydown',
      (e) => {
        if (e.keyCode === KEYCODES.space || e.keyCode === KEYCODES.enter) {
          this.linkActionHandler(e);
        }
      },
      true,
    );
  }

  linkActionHandler(e) {
    /* Prevent 'trickling down' of the click event to the SrfMedia object where another click handler would play
         the media. In the related episodes list there's a caption that's also a link and shouldn't trigger the
         media. */
    e.preventDefault();
    e.stopPropagation();
    let showUrl = this.element.getAttribute('data-pseudo-link-url');
    if (showUrl != '') {
      window.location.href = showUrl;
    }
  }
}
