import { TEXTAREA_VALIDATED, triggerEvent } from '@fec/assets/js/utils/event';

export function init() {
  let Textarea = {};
  $(document).ready(function () {
    Textarea.controller = new controller();
    Textarea.controller.init();
  });
}

let controller = function () {
  let that = this;
  this.maxInputLength = 500;

  this.init = function () {
    $('.js-textarea').each((_, element) => {
      $(element).on('keyup focus input', '.js-textarea-input', () => {
        that.countChars($(element));
      });
      $(element).on('focusin', '.js-textarea-input', () => {
        $(element).find('.js-textarea__info-count').css('opacity', 1);
        $(element)
          .children('.js-textarea__info')
          .addClass('textarea__info--focused');
      });

      $(element).on('focusout', '.js-textarea-input', () => {
        $(element).find('.js-textarea__info-count').css('opacity', 0);
        $(element)
          .children('.js-textarea__info')
          .removeClass('textarea__info--focused');
      });

      $(element).on({
        scroll: that.handleScroll($(element)),
      });
    });
  };

  this.countChars = function ($textarea) {
    let message = $textarea.children('.js-textarea-input').val(),
      len = message.length;

    // In browsers that use \n for newlines and not \n\r, the character count
    // will be different from the backend. In that case we replace \n with
    // \n\r and count again, so that we reach the same count as PHP.
    if (message.indexOf('\n\r') < 0 && message.indexOf('\n') >= 0) {
      len = message.split('\n').join('\n\r').length;
    }

    // Trigger validation notification or not
    let validationId = $textarea.data('validationId') || null;

    // count up available chars
    $textarea.find('.js-textarea-count').text(that.maxInputLength - len);

    if ((len === 0 || len > this.maxInputLength) && validationId) {
      triggerEvent(TEXTAREA_VALIDATED, {
        validationId: validationId,
        valid: false,
      });
    }

    if (len > this.maxInputLength) {
      $textarea.find('.js-textarea__info').addClass('textarea__info--warn');
    } else {
      $textarea.find('.js-textarea__info').removeClass('textarea__info--warn');
      if (validationId) {
        triggerEvent(TEXTAREA_VALIDATED, {
          validationId: validationId,
          valid: true,
        });
      }
    }
  };

  this.handleScroll = function ($textarea) {
    $textarea.scrollTop();
  };
};
