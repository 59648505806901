import { SrfMediaWindow } from './SrfMediaWindow';

const HOOK_SELECTOR = '.js-media-window-container';

export function init() {
  $(HOOK_SELECTOR)
    .filter((_, element) => !$(element).data('media-window-initialized'))
    .each((_, element) => {
      new SrfMediaWindow(element);

      // mark element, so that it won't be initialized again by this module
      $(element).data('media-window-initialized', true);
    });
}
