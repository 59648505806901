import { onEvent } from '@fec/assets/js/utils/event';

const showCommentCount = (count) => {
  // change the icon to the one that leaves some space for the number of comments
  document.querySelectorAll('.js-sharing-bar-comments-icon').forEach((el) => {
    el.classList.remove('svg-icon--comments-zero');
    el.classList.add('svg-icon--comments');
  });
  // write the number of comments to the DOM
  document
    .querySelectorAll('.js-sharing-bar-comments-counter')
    .forEach((el) => (el.textContent = count));
  // show the element that contains the number of comments
  document
    .querySelectorAll('.js-sharing-bar-comments-counter-container')
    .forEach((el) => el.classList.remove('h-element--hide'));
};

const showCorrectCommentsButton = (threadState) => {
  document.querySelectorAll('.js-sharing-bar-comments-button').forEach((el) => {
    if (threadState === 'open') {
      el.classList.add('sharing-bar__comments-button--open');
    } else {
      el.classList.add('sharing-bar__comments-button--closed');
    }

    el.classList.remove('h-element--hide');
  });
};

export function init() {
  // Upon loading, only the sharing button is visible
  // when the event from the comment API is received, the comments button is shown & updated:
  // - if there are comments, the number of comments is shown
  // - otherwise, no number is shown
  // - depending on the status of the comments (open/closed), the button's color changes accordingly
  // - special case: if there are no comments but the comments were open, no button is shown
  // - a good old anchor link handles the scrolling to the comments
  // - InteractionMeasurement.js takes care of tracking

  onEvent({
    eventName: 'commentsApi:stats',
    eventHandler: ({ detail }) => {
      const { threadState, commentsPublished } = detail;
      // do not do anything if there are no comments and the thread is closed
      // (because there is no comments section displayed)
      if (commentsPublished === 0 && threadState === 'closed') {
        return;
      }

      showCorrectCommentsButton(threadState);
      if (commentsPublished > 0) {
        showCommentCount(commentsPublished);
      }
    },
  });
}
