import { SrfLetterboxMedia } from './classes/SrfLetterboxMedia';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';

const MEDIA_SELECTOR = '.js-media';

let loadedPlayers = [];

export function init() {
  $(document)
    .on('click', MEDIA_SELECTOR, (event) => initPlayer(event))
    .on('keypress', MEDIA_SELECTOR, (event) => initByKeypress(event));
}

const initPlayer = (event) => {
  event.preventDefault();
  const elem = event.currentTarget;

  // create a new SrfLetterboxMedia instance if there's not already one for this medium
  if (!getPlayer(elem.id)) {
    loadedPlayers.push(new SrfLetterboxMedia(elem));
  }
};

const initByKeypress = (event) => {
  // letterbox is no longer an iframe, check if the keypress was fired on
  // the element itself or a child (in which case we do nothing)
  if (
    parseInt(event.which) === KEYCODES.enter &&
    event.target === event.currentTarget
  ) {
    initPlayer(event);
  }
};

const getPlayer = (cssId) => {
  for (let i = 0; i < loadedPlayers.length; i++) {
    if (loadedPlayers[i].getId() === cssId) {
      return loadedPlayers[i];
    }
  }
  return null;
};
